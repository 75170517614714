<template>
  <div class="body">
    <img style="width: 100vw;height: var(--384);"
         src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/e76af6ca-80a8-4daa-aae7-ff6fcda41003.png">
    <div
        class="margin-left30"
        style="background:#ffffff;border-radius: var(--20);width: var(--690);
        display: flex;align-items: center;flex-direction: column;
        margin-top: -40vw">
      <div class="flex_center font-size48 font-weightBold"
           style="line-height:var(--66);margin-top: var(--94);color:#E4514F;margin-bottom: var(--22)">
        恭喜你，获得
      </div>
      <div class="flex_center font-size48 font-weightBold"
           style="line-height:var(--124);color:#E4514F;">
        <span class="font-weightBold"
              style="margin-left: var(--14);margin-right: var(--14);
            font-size: var(--88);color: #E4514F">
          {{ userGetAmount }}
        </span>
        <span class="font-weightBold" style="margin-top: var(--20);">
          {{ coinTypeStr }}
        </span>
      </div>
      <div style="height: var(--1);width: 100%;margin-top: var(--40);background: #F0F0F0;border-radius: var(--2);">

      </div>
      <div class="font-color666666 font-size28 flex_able" style="align-items:center;display:flex;width: 100%;
            padding: var(--40) var(--30) var(--20);">
        已领取{{ alreadyGrabNum }}个
      </div>
      <red-pack-record-detail-item
          style="height: calc(100vh - var(--354) - var(--276))"
          :number="number"
          :optimal-id="optimalId"
          :red-pack-list="redPackList"
          :alreadyGrabNum="alreadyGrabNum"/>
    </div>
    <div style="height: var(--190);">

    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import {Api} from "@/utils/net/Api";
import RedPackRecordDetailItem from "@/pages/app/redPack/components/redPackRecordDetailItem.vue";
import {Constants} from "@/utils/constants";
import EncryptUtils from "@/utils/EncryptUtils";

export default {
  components: {RedPackRecordDetailItem},
  data() {
    return {
      userMobile: "",
      totalRedPacketUuid: "",
      redPacketTitle: "",
      userGetAmount: "",//用户领取到的数量
      coinTypeStr: "",//用户领取到的单位
      alreadyGrabNum: "",//红包已经领取的数量
      number: "",//红包数量
      optimalId: 0,//红包最佳id
      totalRedPacketStatus: "",//红包是否过期
      redPackList: [
        {
          getUserName: "",
          getUserHead: "",
          amountAndCoinType: "",
          getTime: "",
          redPacketId: ""
        }
      ],
      haoYouZhuLi: [
        {"title": "红包规则："},
        {"content": "1.领红包流程："},
        {"content": "第一步：下载XWorldApp"},
        {"content": "第二步：使用领红包的手机号登录"},
        {"content": "第三步：在XWorld红包记录中查看收发红包记录"},
        {"content": "2.同一个红包，每个账号只能领取一次"},
      ],
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "X World红包"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "true",
        statusBarBgColor: "#ffffff",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    let userMobile = localStorage.getItem(Constants.userMobile);
    if (!EncryptUtils.isEmpty(userMobile)) {
      this.userMobile = userMobile
    }
    this.totalRedPacketUuid = this.$route.query.totalRedPacketUuid;
    //查红包详情  领取人没有输入手机号
    this.listRedPacketById()
  },
  mounted() {
  },
  methods: {
    // 红包详情
    listRedPacketById() {
      let that = this;
      let param = {
        userMobile: this.userMobile,
        totalRedPacketUuid: this.totalRedPacketUuid
      }
      Api.listRedPacketDetailById(param).then(res => {
        if (res.code === 100) {
          let sendRedPacketDetail = res.data.getRedPacketDetail;
          that.totalRedPacketStatus = sendRedPacketDetail.totalRedPacketStatus //1可领取;2已领完;3过期退回
          that.redPacketTitle = sendRedPacketDetail.redPacketTitle //红包标题
          that.number = sendRedPacketDetail.number
          that.userGetAmount = sendRedPacketDetail.userGetAmount
          that.coinTypeStr = sendRedPacketDetail.coinName
          that.alreadyGrabNum = sendRedPacketDetail.alreadyGrabNum
          that.redPackList = sendRedPacketDetail.redPacketList
          // 最大金额
          let maxAmount = 0
          that.redPackList.reverse().forEach(item => {
            item['getTime'] = item.getTime.replace('T', " ")
            // 最大金额
            if (maxAmount < item.amount) {
              maxAmount = item.amount
              that.optimalId = item.redPacketId
            }
            item['amountAndCoinType'] = item.amount + item.coinName;
          });
        } else {
          Toast(res.msg);
        }
      })
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.oneReceiveClass {
  width: var(--626);
  height: var(--88);
  background: #E4514F;
  border-radius: var(--10);
}
</style>
